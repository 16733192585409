import { createContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import baseTheme from 'theming/baseTheme';

type AppTheme = 'light' | 'dark';
type AppThemeSetting = AppTheme | 'system';

export const ThemeContext = createContext({ theme: 'light', preferredTheme: 'light', switchTheme: () => {}, toggleTheme: () => {} } as {
  theme: AppTheme; // can only be light/dark as this is what is used throughout the app for colours
  preferredTheme: AppThemeSetting; // used to determine user preferences e.g settings tray theme select (includes system as well)
  switchTheme: (val: AppThemeSetting) => void;
  toggleTheme: () => void;
});

interface ThemeHandlerProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
}

const ThemeHandler = ({ children, isAuthenticated }: ThemeHandlerProps) => {
  const [theme, setTheme] = useState<AppTheme>('light');
  const [preferredTheme, setPreferredTheme] = useState<AppThemeSetting>((localStorage.getItem('theme') as AppThemeSetting) || theme);
  const dynamicTheme = { ...baseTheme, mode: theme };

  //// System theme only
  const systemQuery = window.matchMedia('(prefers-color-scheme: dark)');
  const handleSystemThemeChange = (e: MediaQueryListEvent) => {
    if (localStorage.getItem('theme') === 'system') {
      setTheme(e.matches ? 'dark' : 'light');
    }
  };
  const getSystemTheme = (): 'dark' | 'light' => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };
  ////

  const switchTheme = (newTheme: AppThemeSetting) => {
    setTheme(newTheme === 'system' ? getSystemTheme() : newTheme);
    setPreferredTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };
  const toggleTheme = () => {
    switchTheme(theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    const getThemePreference = (): AppTheme => {
      const lsVal = localStorage.getItem('theme') as AppThemeSetting;
      if (lsVal === null) {
        return 'light';
      } else if (lsVal === 'system') {
        systemQuery.addEventListener('change', handleSystemThemeChange);
        return getSystemTheme();
      } else {
        return lsVal;
      }
    };

    setTheme(isAuthenticated ? getThemePreference() : 'light');

    return () => {
      systemQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, [isAuthenticated, systemQuery]);

  return (
    <ThemeContext.Provider value={{ theme, preferredTheme, switchTheme, toggleTheme }}>
      <ThemeProvider theme={dynamicTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeHandler;
